import Link from '@/modules/I18n/components/Link';
import { useTranslation } from 'next-i18next';
import CustomImage from '@/components/CustomImage';
import contactFormLogo from '@/images/contact-form-7-logo.png';
import wooCommerceLogo from '@/images/woocommerce-logo.png';
import magentoLogo from '@/images/magento-logo.png';

const WebshopPlugins = () => {
  const { t } = useTranslation('common');

  return (
    <div className="flex items-center gap-8">
      <div>
        <Link href="https://www.postcode-checkout.nl/plug-ins/standaard/contactform-7">
          <CustomImage
            src={contactFormLogo}
            alt={t('common:logo', { company: 'Contact Form 7' })}
            sizes="(min-width: 768px) 260px, 33vw"
          />
        </Link>
      </div>
      <div>
        <Link href="https://www.postcode-checkout.nl/plug-ins/standaard/wordpress-woocommerce">
          <CustomImage
            src={wooCommerceLogo}
            alt={t('common:logo', { company: 'WooCommerce' })}
            sizes="(min-width: 768px) 260px, 33vw"
          />
        </Link>
      </div>
      <div>
        <Link href="https://www.postcode-checkout.nl/plug-ins/standaard/magento">
          <CustomImage
            src={magentoLogo}
            alt={t('common:logo', { company: 'Magento' })}
            sizes="(min-width: 768px) 260px, 33vw"
          />
        </Link>
      </div>
    </div>
  );
};

export default WebshopPlugins;
