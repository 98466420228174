import { useTranslation } from 'next-i18next';
import CustomImage from '@/components/CustomImage';
import EGentic from '@/images/egentic.png';
import Engelbert from '@/images/engelbert.png';
import Flip4New from '@/images/flip4new.png';
import Proleagion from '@/images/proleagion.png';

export default function DE() {
  const { t } = useTranslation('common');
  return (
    <>
      <div>
        <CustomImage
          src={EGentic}
          alt={t('logo', { company: 'EGentic' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={Engelbert}
          alt={t('logo', { company: 'Engelbert Strauss' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={Flip4New}
          alt={t('logo', { company: 'Flip 4 New' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={Proleagion}
          alt={t('logo', { company: 'Proleagion' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
    </>
  );
}
