/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { type ReactElement } from 'react';
import { useTranslation } from 'next-i18next';
import Link from '@/modules/I18n/components/Link';
import Head from 'next/head';
import { useRouter } from 'next/router';
import getCanonical from '@/utils/canonical';
import Page from '@/layouts/Page';
import USP from '@/components/USP';
import Pricing from '@/components/Pricing';
import WebshopHomePricing from '@/components/WebshopHomePricing';
import TrustedBy from '@/components/TrustedBy/TrustedBy';
import WebshopPlugins from '@/components/WebshopPlugins';
import AddressValidationDemo from '@/components/AddressValidationdDemo/AddressValidationDemo';
import { type PricingResults } from '@/schemas/DownloadsPage';
import useStore from '@/store/user';

type Props = {
  pricingData: PricingResults;
  demoInfo: unknown;
  requiredSuggestParams: unknown;
};

export default function Home({ pricingData, demoInfo, requiredSuggestParams }: Props) {
  const userCountry = useStore((state) => state.country);
  const { t } = useTranslation(['home', 'common']);

  const router = useRouter();

  const canonicalUrl = getCanonical(router.asPath, router);

  return (
    <>
      <Head>
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      <section className="bg-blue text-white">
        <div className="inner flex flex-col gap-8 py-10 md:grid md:grid-cols-2 md:gap-[4vw]">
          <article className="flex flex-col gap-3 self-start">
            <h1>{t('home:top_heading')}</h1>
            <h2 className="text-lg">{t('home:top_autocomplete')}</h2>
            <p className="mt-3">{t('home:top_subheading1')}</p>
            <p className="mt-3">{t('home:top_subheading2')}</p>
            <p className="mt-3">{t('home:top_subheading3')}</p>
          </article>
          <article className="flex flex-col gap-4">
            <div className="self-center rounded-md bg-white px-8 py-6 text-black">
              <AddressValidationDemo
                demoInfo={demoInfo}
                requiredSuggestParams={requiredSuggestParams}
              ></AddressValidationDemo>
            </div>

            <div className="flex items-center justify-end underline">
              <Link
                href="/demo/address-validation/nl"
                className="flex items-center gap-2 text-gray"
              >
                {t('view_all_demos')}
                <svg
                  className="inline-block h-4 w-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 5l7 7-7 7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                  />
                </svg>
              </Link>
            </div>
          </article>
        </div>
      </section>
      <section className="bg-gray-lighter">
        <USP />
      </section>
      <section className="border-t-2 border-black border-opacity-5 bg-gray-lighter">
        <Pricing pricingData={pricingData} />
      </section>
      <section className="bg-blue text-center text-white" id="name">
        <div className="inner py-10">
          <h2 className="text-center">{t('home:start_two_month_trial')}</h2>
          <p className="py-5 text-center text-lg font-normal">
            {t('home:create_account_claim_two_month_trial')}
          </p>
          <Link
            href={`${process.env.NEXT_PUBLIC_CMS_BASE_PATH}`}
            className="btn bg-white text-blue"
          >
            {t('free_trial')}
          </Link>
        </div>
      </section>
      <section className="bg-gray-lighter">
        <div className="inner grid gap-4 py-10 md:grid-cols-2">
          <div className="flex flex-col gap-8">
            <h2>Webshops</h2>
            <p>{t('webshop_info_1')}</p>
            <p>
              {t('webshop_info_2')}{' '}
              <Link href="/webshops#plugins" className="text-blue underline">
                {t('webshop_info_view_all_plugins')}
              </Link>
            </p>
            <WebshopPlugins></WebshopPlugins>
          </div>
          <div className="relative">
            <span className="blob"></span>
            <WebshopHomePricing
              className="mx-auto py-10 sm:w-5/6 lg:w-4/6"
              pricingData={pricingData}
            ></WebshopHomePricing>
          </div>
        </div>
      </section>
      <section className="border-t-2 border-black border-opacity-5">
        <div className="inner py-10">
          <h2 className="mb-10 text-center md:mb-20">{t('trusted_by')}</h2>
          <div className="companies grid items-center justify-between gap-x-8">
            <TrustedBy country={userCountry}></TrustedBy>
          </div>
        </div>
      </section>
    </>
  );
}

Home.GetLayout = function GetLayout(page: ReactElement) {
  const { t } = useTranslation('home');
  return (
    <Page description={t('description')} title={t('title')}>
      {page}
    </Page>
  );
};

export async function getStaticProps({ locale }: { locale: string }) {
  let res = await fetch(`${process.env.NEXT_PUBLIC_INTERNAL_API}/v2/subscriptions/types/amount`);
  const pricingData = (await res.json()) as PricingResults;

  res = await fetch(`${process.env.NEXT_PUBLIC_V2_API_URL}/openapi.json`);
  const opendata = await res.json();

  let demoInfo = new Map();
  Object.entries(opendata.paths).forEach((pathObject) => {
    const path = pathObject[0];
    if (path.startsWith(`/autocomplete`)) {
      demoInfo.set(path.split('/')[2], pathObject[1]);
    }
  });
  demoInfo = [...demoInfo];

  let requiredSuggestParams = new Map();
  Object.entries(opendata.paths).forEach((pathObject) => {
    const path = pathObject[0];
    if (path.startsWith(`/suggest`)) {
      requiredSuggestParams.set(path.split('/suggest/')[1], pathObject[1]);
    }
  });
  requiredSuggestParams = [...requiredSuggestParams];

  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'common',
        'home',
        'address-validation',
        'pricing',
      ])),
      // Will be passed to the page component as props
      pricingData,
      demoInfo,
      requiredSuggestParams,
    },
  };
}
