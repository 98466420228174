import { useTranslation } from 'next-i18next';
import CustomImage from '@/components/CustomImage';
import AlternateBe from '@/images/alternate.png';
import Ticketmatic from '@/images/ticketmatic.png';
import BainCompany from '@/images/baincompany.png';
import Markedeer from '@/images/markedeer.png';
import Skarabee from '@/images/skarabee.png';
import Ecoto from '@/images/ecoto.png';
import Torfs from '@/images/torfs.png';
import BlueFrontend from '@/images/bluefrontend.png';
import Crossuite from '@/images/crossuite.png';

export default function BE() {
  const { t } = useTranslation('common');
  return (
    <>
      <div>
        <CustomImage
          src={AlternateBe}
          alt={t('logo', { company: 'Alternate.be' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={Ticketmatic}
          alt={t('logo', { company: 'Ticketmatic' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={BainCompany}
          alt={t('logo', { company: 'Bain & Company' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={Markedeer}
          alt={t('logo', { company: 'Markedeer' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={Skarabee}
          alt={t('logo', { company: 'Skarabee' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={Ecoto}
          alt={t('logo', { company: 'Ecoto' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={BlueFrontend}
          alt={t('logo', { company: 'Blue Frontend' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={Crossuite}
          alt={t('logo', { company: 'Crossuite' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={Torfs}
          alt={t('logo', { company: 'Torfs' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
    </>
  );
}
