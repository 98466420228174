import NL from '@/components/TrustedBy/NL';
import BE from '@/components/TrustedBy/BE';
import DE from '@/components/TrustedBy/DE';

export default function TrustedBy({ country }: { country: string | undefined }) {
  switch (country) {
    case 'NL':
      return <NL></NL>;
    case 'BE':
      return <BE></BE>;
    case 'DE':
      return <DE></DE>;
    default:
      return <NL></NL>;
  }
}
