import { useTranslation } from 'next-i18next';
import CustomImage from '@/components/CustomImage';
import Primera from '@/images/primera.png';
import Bol from '@/images/bol.png';
import EngieEnergie from '@/images/engie-energie.png';
import Bruna from '@/images/bruna.png';
import Independer from '@/images/independer.png';
import Intratuin from '@/images/intratuin.png';
import NederlandseEnergieMaatschappij from '@/images/nederlandse-energie-maatschappij.png';
import Vattenfall from '@/images/vattenfall.png';
import RonaldMcDonaldKinderfonds from '@/images/kinderfonds.png';
import StichtingHetVergetenKind from '@/images/het-vergeten-kind.png';
import YoungCapital from '@/images/young-capital.png';
import Zeeman from '@/images/zeeman.png';

export default function NL() {
  const { t } = useTranslation('common');
  return (
    <>
      <div>
        <CustomImage
          src={Bol}
          alt={t('logo', { company: 'Bol' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={Vattenfall}
          alt={t('logo', { company: 'Vattenfall' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={EngieEnergie}
          alt={t('logo', { company: 'Engie Energie' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={Bruna}
          alt={t('logo', { company: 'Bruna' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={Independer}
          alt={t('logo', { company: 'Independer' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={Intratuin}
          alt={t('logo', { company: 'Intratuin' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={NederlandseEnergieMaatschappij}
          alt={t('logo', { company: 'Nederlandse Energie Maatschappij' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={YoungCapital}
          alt={t('logo', { company: 'Young Capital' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={RonaldMcDonaldKinderfonds}
          alt={t('logo', { company: 'Ronald McDonald Kinderfonds' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={StichtingHetVergetenKind}
          alt={t('logo', { company: 'Stichting Het Vergeten Kind' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={Primera}
          alt={t('logo', { company: 'Primera' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
      <div>
        <CustomImage
          src={Zeeman}
          alt={t('logo', { company: 'Zeeman' })}
          sizes="(min-width: 768px) 185px, 30vw"
        />
      </div>
    </>
  );
}
