import useSWR from 'swr';
import buildURLQuery from '@/utils/buildURLQuery';
import { useTranslation } from 'next-i18next';
import mapHasAllValues from '@/utils/mapHasAllValues';
import { type ChangeEvent, useEffect, useRef, useState } from 'react';
import useStore from '@/store/user';
import Alert from '@/components/Alert';
import GeoInput from '@/components/GeoInput';
import { type Parameter } from '../../schemas/OpenAPIParameter';

interface IProps {
  demoInfo: unknown;
  requiredSuggestParams: unknown;
}

export default function AddressValidationDemo({ demoInfo, requiredSuggestParams }: IProps) {
  const { t } = useTranslation('address-validation');

  const OpenAPIinfo: Map<string, unknown> = new Map(demoInfo);

  const requiredSuggestParameters = new Map(requiredSuggestParams);

  const userCountry = useStore((state) => state.country);

  const autocompleteInfo = OpenAPIinfo.get(countryToUse());

  const parameters: Parameter[] = autocompleteInfo.get.parameters;

  const responseFields =
    autocompleteInfo.get.responses['200'].content['application/json'].schema.properties;

  const requiredParams = parameters.filter((param: unknown) => param.required === true);

  const [inputs, setInputs] = useState<Map<string, string>>(new Map());

  const [responses, setResponses] = useState<Map<string, undefined>>(new Map());

  function countryToUse() {
    if (OpenAPIinfo.get(userCountry.toLowerCase())) {
      return userCountry.toLowerCase();
    }
    return 'nl';
  }

  // Check for empty streetnumberandpremise and update responses
  function handleResponse(location?: Map<string, undefined>) {
    if (!location) {
      setResponses(new Map());
      return;
    }

    if (!String(location.get('key')).includes('streetNumberAndPremise')) {
      location.set('street', undefined);
    }
    setResponses(location);
  }

  const inputFieldsRef = useRef<Array<HTMLInputElement | null>>([]);

  useEffect(() => {
    inputFieldsRef.current = inputFieldsRef.current.slice(0, parameters.length);
  }, [parameters]);

  useEffect(() => {
    // clear input and response fields on mount and on country change
    setInputs(new Map());
    setResponses(new Map());
  }, [userCountry]);

  function requiredInputs(inputField: string) {
    const requiredInputs: string[] = [];
    if (requiredSuggestParameters.has(`${countryToUse()}/${inputField}`)) {
      const requests = requiredSuggestParameters.get(`${countryToUse()}/${inputField}`) as unknown;
      (Object.values(requests)[0] as unknown).parameters.map((param: unknown) => {
        if (param.required) {
          requiredInputs.push(param.name);
        }
      });
    }
    return requiredInputs;
  }

  const desiredOutputs = ['region', 'province', 'state', 'department', 'settlement', 'street'];
  const countrySpecific: { [key: string]: string[] } = {
    // de: ['fieldName'],
  };

  const authKey = process.env.NEXT_PUBLIC_AUTH_KEY;

  const { data, error } = useSWR(
    userCountry &&
      mapHasAllValues(inputs, requiredParams, 'name') &&
      requiredParams.every(
        (requiredParam) =>
          (document.getElementById(requiredParam.name) as HTMLInputElement).validity.valid,
      )
      ? `/autocomplete/${countryToUse()}?${buildURLQuery({
          authKey,
          ...Object.fromEntries(inputs),
        })}`
      : null,
    {
      dedupingInterval: 10000, // don't send the same request more than once per 10 seconds
    },
  );

  const explanation = t('explanation_NL', { interpolation: { escapeValue: false } });

  return (
    <div>
      <h3>{t('try_now_free')}</h3>
      <p dangerouslySetInnerHTML={{ __html: explanation }} className="mb-6 mt-2"></p>
      <form className="mt-4 grid grid-cols-2 gap-x-4 gap-y-2 lg:grid-cols-4" autoComplete="off">
        {/* input fields */}
        {parameters.map((parameter: Parameter, i: number) => {
          if (parameter.name !== 'streetNumber' && parameter.name !== 'premise') {
            return (
              <div key={parameter.name} className="relative col-span-2 flex flex-col lg:col-span-2">
                <GeoInput
                  autoComplete="chrome-off"
                  label={t(parameter.name)}
                  name={
                    parameter.name === 'streetNumberAndPremise' ? 'streetNumber' : parameter.name
                  }
                  required={parameter.required}
                  placeholder={parameter.example?.toString() || ' '}
                  value={inputs.get(parameter.name) || ''}
                  pattern={parameter.schema.pattern}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleResponse();
                    setInputs(new Map(inputs).set(parameter.name, e.target.value as string));
                  }}
                  country={countryToUse()}
                  suggest
                  requiredInputsToSuggest={requiredInputs(parameter.name)}
                  onSelectSuggestionHandler={(e) => {
                    const parameterValue =
                      parameter.name === 'streetNumberAndPremise'
                        ? `${e.streetNumber} ${e.premise ? e.premise : ''}`
                        : e[parameter.name];

                    setResponses(new Map(Object.entries(e)));
                    setInputs(new Map(inputs).set(parameter.name, parameterValue));
                  }}
                  inputs={inputs}
                  innerRef={(el: HTMLInputElement | null) => inputFieldsRef.current[i] === el}
                  handleResponse={handleResponse}
                ></GeoInput>
              </div>
            );
          }
        })}

        {/* general response fields */}
        {Object.keys(responseFields).map((responseField) => {
          // only display if an output is desired and it's not in input fields
          if (
            desiredOutputs.includes(responseField) &&
            !parameters.find((parameter) => parameter.name === responseField)
          ) {
            return (
              <div key={`${responseField}_response`} className="col-span-full">
                <GeoInput
                  label={t(responseField)}
                  name={`${responseField}_response`}
                  readOnly
                  value={data?.[responseField] || responses.get(responseField) || ''}
                  placeholder={t('automatic')}
                ></GeoInput>
              </div>
            );
          }
        })}

        {/* country-specific response fields */}
        {Object.entries(countrySpecific).map((countryResponseKV) => {
          const country = countryResponseKV[0];
          if (countryToUse() === country.toLowerCase()) {
            const responseFields = countryResponseKV[1];
            return responseFields.map((responseField) => {
              return (
                <div key={`${responseField}_response`} className="col-span-full">
                  <GeoInput
                    label={t(responseField)}
                    name={`${responseField}_response`}
                    readOnly
                    value={data?.[responseField] || responses.get(responseField)}
                    placeholder={t('automatic')}
                  ></GeoInput>
                </div>
              );
            });
          }
        })}

        {/* coordinates */}
        <div className="col-span-2 flex flex-col lg:col-span-2">
          <GeoInput
            id="lat"
            label={t('lat')}
            name={t('lat')}
            placeholder={t('automatic')}
            readOnly
            value={data?.lat || responses.get('lat') || ''}
          ></GeoInput>
        </div>

        <div className="col-span-2 flex flex-col lg:col-span-2">
          <GeoInput
            id="lng"
            label={t('lng')}
            name={t('lng')}
            placeholder={t('automatic')}
            readOnly
            value={data?.lng || responses.get('lng') || ''}
          ></GeoInput>
        </div>

        {/* errors */}
        {error ? <Alert type="error">{error}</Alert> : ''}
        {inputs.get(parameters[1] ? parameters[1].name : '')
          ? data?.errors && (
              <Alert type="error">
                {t(data?.error_id)}
                {data?.errors[0].parameter ? ` ${t(data?.errors[0].parameter)}` : ''}
              </Alert>
            )
          : ''}
      </form>
    </div>
  );
}
