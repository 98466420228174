import Link from '@/modules/I18n/components/Link';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';

import Checkbox from '@/components/Checkbox';
import PricingPackage from '@/components/PricingPackage';
import useStore from '@/store/user';
import type { PricingResults } from '@/schemas/DownloadsPage';

export default function Pricing({ pricingData }: { pricingData: PricingResults }) {
  const { t } = useTranslation('pricing');

  const userCountry = useStore((state) => state.country);
  const selectedCountries = useStore((state) => state.selectedCountries);
  const addCountry = useStore((state) => state.addCountry);
  const removeCountry = useStore((state) => state.removeCountry);

  const [prices, setPrices] = useState(new Map<string, number>());
  const periodDiscount = 0;

  const countries = useCallback(() => {
    return Object.keys(pricingData.packages?.at(0)?.countries || {}).sort();
  }, [pricingData]);

  function toggleCountry(name: string) {
    selectedCountries.has(name) ? removeCountry(name) : addCountry(name);
    setNewPrices();
  }

  function setNewPrices() {
    const newPrices = new Map(prices);

    pricingData.packages.forEach((pricingPackage) => {
      let price = 0;

      if (selectedCountries.size !== 0) {
        const basePrice = pricingPackage.pricePerCountry;
        const discountPrice = basePrice - pricingPackage.multiCountryDiscount;
        const totalDiscount = discountPrice * (selectedCountries.size - 1);
        price = (basePrice + totalDiscount) * (1 - periodDiscount / 100);
      }

      newPrices.set(pricingPackage.name, price);
    });

    setPrices(newPrices);
  }

  useEffect(() => {
    setNewPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountries, periodDiscount]);

  useEffect(() => {
    if (countries().includes(userCountry)) {
      if (!selectedCountries.has(userCountry)) {
        toggleCountry(userCountry);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, userCountry]);

  return (
    <div className="inner py-10">
      <h2 className="text-center">{t('packages')}</h2>
      <p className="my-4 text-center text-lg font-normal">
        {t('choose_package')}&nbsp;
        <Link href="/pricing" className="text-black underline">
          {t('view_all_packages')}
        </Link>
      </p>
      <p className="text-center md:text-left">{t('select_countries')}</p>
      <div className="sticky top-24 z-[5] my-4 grid grid-cols-2 items-center gap-2 rounded bg-gray-lighter p-4 shadow-xl md:static md:flex md:flex-row md:gap-8 md:p-0 md:shadow-none">
        {countries().map((c) => {
          return (
            <Checkbox
              key={c.toUpperCase()}
              checked={selectedCountries ? selectedCountries.has(c.toUpperCase()) : false}
              handleCheckboxChange={toggleCountry}
              id={c.toUpperCase()}
              name={c.toUpperCase()}
              isCountry
            />
          );
        })}
      </div>
      <div className="pricing-items my-10 grid gap-8 md:grid-cols-3">
        {pricingData.packages
          .filter((p) => p.name !== 'webshop' && p.name !== 'specials')
          .map((pricingPackage) => {
            return (
              <PricingPackage
                key={pricingPackage.name}
                prices={prices}
                pricingPackage={pricingPackage}
                selectedCountries={selectedCountries}
                onClick={undefined}
              />
            );
          })}
      </div>
    </div>
  );
}
